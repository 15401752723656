var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import MuiCheckbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import { useId, useRef, useState, } from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CheckboxIcon } from '@icons/custom/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from '@icons/custom/checkbox-checked.svg';
import { Link as LinkComponent } from '../shared';
const CheckboxContainer = styled.div(props => ({
    display: 'flex',
    '&.MuiFormControlLabel-root': {
        margin: 0,
        '&:not(.Mui-disabled):hover': {
            svg: {
                borderRadius: 6,
                boxShadow: `0px 0px 0px 3px ${props.theme.customColors.checkbox.checkboxHover}`,
            },
        },
    },
    'label.MuiFormLabel-root': {
        color: props.disabled ?
            props.theme.customColors.checkbox.textDisabled :
            props.theme.customColors.checkbox.textDefault,
        cursor: props.disabled ? 'default' : 'pointer',
        lineHeight: '24px',
    },
}));
const Checkbox = styled(MuiCheckbox, { shouldForwardProp: propName => propName !== 'hasHover' })(props => ({
    padding: '0 12px 0 0',
    opacity: props.disabled ? 0.5 : 1,
    alignItems: 'start',
    svg: {
        boxShadow: props.hasHover ? '0px 0px 0px 3px rgba(136, 144, 150, 0.10)' : 'unset',
        borderRadius: props.theme.baseSize,
    },
    '&:hover': {
        backgroundColor: 'unset',
    },
}));
const RulesCheckbox = (_a) => {
    var { onTermsUsageClick = undefined, onPrivacyPolicyClick = undefined, onCheckboxClick = undefined, disabled = undefined } = _a, props = __rest(_a, ["onTermsUsageClick", "onPrivacyPolicyClick", "onCheckboxClick", "disabled"]);
    const ref = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();
    const checkboxId = useId();
    const makeHoverUnactive = () => setIsHovered(false);
    const makeHoverActive = () => {
        if (!disabled) {
            setIsHovered(true);
        }
    };
    const handleCheckboxClick = () => {
        var _a;
        if (onCheckboxClick && typeof ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.value) === 'boolean' && !disabled) {
            onCheckboxClick(ref.current.value);
        }
    };
    return (_jsxs(CheckboxContainer, { disabled: disabled, children: [_jsx(Checkbox, Object.assign({}, props, { onClick: handleCheckboxClick, hasHover: isHovered, disabled: disabled, id: checkboxId, icon: _jsx(CheckboxIcon, {}), checkedIcon: _jsx(CheckboxCheckedIcon, {}), onMouseEnter: makeHoverActive, onMouseLeave: makeHoverUnactive, ref: ref })), _jsx(FormLabel, { htmlFor: checkboxId, onMouseEnter: makeHoverActive, onMouseLeave: makeHoverUnactive, children: Trans({
                    i18nKey: 'auth.sign_up.steps.credentials.terms_of_usage_text',
                    values: {
                        termsOfUsage: t('auth.sign_up.steps.credentials.terms_of_usage_link_text', { ns: 'common' }),
                        privacyPolicy: t('auth.sign_up.steps.credentials.privacy_policy_link_text', { ns: 'common' }),
                    },
                    ns: 'common',
                    components: [
                        _jsx(LinkComponent, { rel: 'noopener noreferrer', onClick: onTermsUsageClick, onMouseEnter: makeHoverUnactive, onMouseLeave: makeHoverActive }, 'terms-of-usage'),
                        _jsx(LinkComponent, { rel: 'noopener noreferrer', onClick: onPrivacyPolicyClick, onMouseEnter: makeHoverUnactive, onMouseLeave: makeHoverActive }, 'privacy-policy'),
                    ],
                }) })] }));
};
export default RulesCheckbox;
